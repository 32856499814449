<template>
    <v-card style="border-radius: 10px">
      <v-container fluid>
          <v-layout row wrap>
              <v-flex xs12 md8 class="">
                <v-layout row class="grey--text darken-2">
                    <v-flex xs12 >
                        <p class="body-1">Are you sure you want to cancel this group?</p>
                        <p class="">Input your password to confirm?</p>
                    </v-flex>
                </v-layout>
                <v-layout row class="grey--text darken-2">
                    <v-flex xs12 >
                        <v-text-field class="pa-0"
                            id="password" v-model="password" ref="password"
                            label="Password "
                            :rules="[v => !!v || 'A confirmation password is required']"
                            outline
                            color="teal "
                            min="8"
                            :append-icon="e3 ? 'visibility' : 'visibility_off'"
                            @click:append="() => (e3 = !e3)"
                            :type="e3 ? 'password' : 'text'"
                            counter
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <div class="text-xs-center">
                    <v-btn  class="white--text elevation-6" round color="teal darken-2" >
                        <span> Save Changes</span>
                    </v-btn>
                    <v-btn  class="dark--text elevation-6" round color="white" :to="'/njangi/groups/configure/detail/'+$route.params.new_group_id">
                        <span> Cancel</span>
                    </v-btn>
                </div>
              </v-flex>
              <v-flex md4 class="hidden-sm-and-down">
                    <v-img
                        :src="require('@/assets/pose-m-1.png')"
                        aspect-ratio="2"  
                        contain
                        height="200"
                        width="250"
                        style="cursor: pointer"
                    ></v-img>
              </v-flex>
          </v-layout>
      </v-container>
    </v-card>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                e1: true,
                e2: true,
                e3: true,

                current_password: "",
                new_password: "",
                password: "",
              
            }
        },
        
        methods:{

        },
    }
</script>
